<template>
<!-- zx 2021-6-8 -->
<div>
 <!-- <div class="bounce1" v-lazy:background-image="require('./ByteDance_img/img6.png')"> -->
 <div class="bounce1">
    <div class="start">
       <img v-lazy='require("./ByteDance_img/IE4u44mX.webp")' class="start_img">
     </div>
   <div class="bannert">
      <div class="byte1">字节跳动</div>
    <div class="byte2">多端数据互通，视频化+电商实现拓宽新渠道解决新增长</div>
   </div>
    <div class="byte1_1"></div>
 </div>
 <div class="bounce2" >
   <div class="byte3">前瞻性的技术预判</div>
   <!-- <div class="byte4" v-lazy:background-image="require('./ByteDance_img/img.png')"> -->
   <div class="byte4">
      <div class="img-3">
       <img v-lazy='require("./ByteDance_img/zrOicqat.webp")' class="img-4">
     </div>
      <img src='./ByteDance_img/img5.png' class="byte5">
   </div>
   
 </div>
 <div class="bounce3">
   <div class="byte11">火山引擎独特优势</div>
   <div class="byte12 mt60">
       <div class="byte13">
           <img src="./ByteDance_img/icon_7.png" class="byte15">
           <div class="byte16">
             <div class="btye17">敏捷高效的技术体系</div>
             <div class="btye18">
               拥抱新技术，以快速研发能力，为企业打造敏捷高效的业务应用和技术平台
             </div>
           </div>
       </div>
        <div class="byte13 ml30">
           <img src="./ByteDance_img/icon_2.png" class="byte15">
           <div class="byte16">
             <div class="btye17">首屈一指的智能算法</div>
             <div class="btye18">
               海量日活数据锤炼的智能算法，驱动更加精准的实时推荐和实时决策
             </div>
           </div>
       </div>
   </div>
    <div class="byte12 mt30">
       <div class="byte13">
           <img src="./ByteDance_img/icon_6.png" class="byte15">
           <div class="byte16">
             <div class="btye17">多触点的交互体验</div>
             <div class="btye18">
               在全渠道快速构建触点，提供个性化、多形式的交互体验
             </div>
           </div>
       </div>
        <div class="byte13 ml30">
           <img src="./ByteDance_img/icon_3.png" class="byte15">
           <div class="byte16">
             <div class="btye17">独有的增长方法论</div>
             <div class="btye18">
               凝练字节成功的增长实践，输出可持续、可复制、可规模化的增长策略
             </div>
           </div>
       </div>
   </div>
    <div class="byte12 mt30">
       <div class="byte13">
           <img src="./ByteDance_img/icon_5.png" class="byte15">
           <div class="byte16">
             <div class="btye17">强大的内容制作能力</div>
             <div class="btye18">
               全网一流的创意服务与生态，具备丰富的智能化内容生成和管理工具
             </div>
           </div>
       </div>
        <div class="byte13 ml30">
           <img src="./ByteDance_img/icon_4.png" class="byte15">
           <div class="byte16">
             <div class="btye17">高价值的数据洞察</div>
             <div class="btye18">
               基于全网全面的前链路流量数据，提炼多维度、高价值的精准洞察
             </div>
           </div>
       </div>
   </div>
   
 </div>
 <div class="bounce4">
    <div class="byte11">文旅流量运营</div>
    <div class="byte19">景区联合图虫&amp;抖音定制拍摄风景大片，KOL体验评测等，图虫为景区提供创意定制拍摄策划+拍摄制作服务</div>
    <div class="byte20">
      <div class="img-5">
         <img v-lazy="require('./ByteDance_img/4rY51j31.webp')" class="byte21">
      </div>
      <div class="img-5">
         <img  v-lazy="require('./ByteDance_img/Rm7EGibI.webp')" class="byte21 ml30">
      </div>
      <div class="img-5">
         <img  v-lazy="require('./ByteDance_img/AIbZcQ0J.webp')"  class="byte21 ml33">
      </div>
      
      
    </div>
 </div>
 <div class="bounce5">
   <div class="byte11">
     一条内容的旅程
   </div>
   <div class="byte25 mt60">
     <div class="byte26">
       <img src="./ByteDance_img/icon (1).png" class="byte23">
       <div class="byte27">
         <div class="byte28">内容制作</div>
         <div class="byte29 mt16">
           <img src="./ByteDance_img/1.png" class="byte24">
           <div class="byte30">手机摄像头拍摄</div>
         </div>
         <div class="byte29 mt8">
           <img src="./ByteDance_img/1.png" class="byte24">
           <div class="byte30">上传至剪映剪辑</div>
         </div>
         <div class="byte31 mt8">
           <img src="./ByteDance_img/1.png" class="byte32">
           <div class="byte33">直接引入头条资讯和抖音短视频等</div>
         </div>
       </div>
     </div>
     <div class="byte26 ml30">
       <img src="./ByteDance_img/icon(2).png" class="byte23">
       <div class="byte27">
         <div class="byte28">内容审核</div>
         <div class="byte31 mt16">
           <img src="./ByteDance_img/1.png" class="byte32">
           <div class="byte30">先经过机器审核，标注风险点</div>
         </div>
         <div class="byte31 mt8">
           <img src="./ByteDance_img/1.png" class="byte32">
           <div class="byte33">再进行人工审核，快速过检双保险</div>
         </div>
       </div>
     </div>
     <div class="byte26 ml30">
       <img src="./ByteDance_img/icon(4).png" class="byte23">
       <div class="byte27">
         <div class="byte28">内容打标</div>
           <div class="byte40 mt16"> NLP、OCR等模型进行标签
                    <br />
                    生成，包括关键词抽取、关
                    <br />
                    键实体抽取、主题分类等</div>
         </div>
     </div>
   </div>
    <div class="byte25 mt30">
     <div class="byte26">
       <img src="./ByteDance_img/icon (1).png" class="byte23">
       <div class="byte27">
         <div class="byte28">内容消费</div>
         <div class="byte29 mt16">
           <div class="byte40">
             用户点击内容/内容下方产品
                    <br />
                    数据反哺到引入环节与推荐算法
           </div>
         </div>
       </div>
     </div>
     <div class="byte26 ml30">
       <img src="./ByteDance_img/icon(3).png" class="byte23">
       <div class="byte27">
         <div class="byte28">内容分发</div>
         <div class="byte29 mt16">
           <div class="byte40">个性化推荐算法，同时支持运营干预</div>
         </div>
       </div>
     </div>
     <div class="byte26 ml30">
       <img src="./ByteDance_img/icon(5).png" class="byte23">
       <div class="byte27">
         <div class="byte28">旅游产品建联</div>
         <div class="byte29 mt16">
           <div class="byte40"> 
              根据内容关联旅游周边产品，
                    <br />
                    提升产品转化
           </div>
         </div>
       </div>
     </div>
   </div>
 </div>

<div>
  <Header></Header>
  <Footer></Footer>
  </div>
</div>
</template>
<script>
//引入头部组件
import Header from "@/components/Header.vue";
// 引入底部组件
import Footer from "@/components/Footer.vue";
export default {
components: {
    Header,
    Footer,
  },
}
</script>
<style lang="scss" scoped>
/* 动画效果 */
@keyframes sport {
  0% {
    transform: translateY(80px);
    opacity: 1;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

.bounce1{
  height: 600px;
  background-repeat:  no-repeat;
  background-size: 100% 100%;
}
.start_img{
   height: 600px;
  width: 100%;
}
.start{
   height: 600px;
  width: 100%;
  display: flex;
    justify-content: center;
    align-items: center;
    img[lazy="loading"] {
		width: 200px;
		height: 200px;
	}
	// 实际图片大小
	img[lazy="loaded"] {
		 height: 600px;
  width: 100%;
	}
}
.bannert{
  z-index: 5;
   position: absolute;
    left: 361px;
    top: 230px;

}
.byte1_1{
  position: absolute;
  top: 0;
  left: 0;
    height:600px;
    width:100%;
    background-color: rgba(0,0,0,.4);
}
.byte1{
   color: rgba(255, 255, 255, 1);
  font-size: 48px;
  // margin-top: -370px;
    // padding-left: 360px;
  text-align: left;
}
.byte1{
   animation: sport 0.5s;
  transform: translateY(0px);
}
.byte2{
   color: rgba(255, 255, 255, 1);
  font-size: 24px;
  text-align: left;
  margin-top: 20px;
  // padding-left: 360px;
}
.byte2{
   animation: sport 0.5s;
  transform: translateY(0px);
}
.bounce2{
  padding-top: 80px;
  padding-bottom: 100px;
  margin: auto;
}
.byte3{
  color: rgba(51, 51, 51, 1);
  font-size: 40px;
}
.byte4{
  width: 1200px;
  height: 340px;
  // background-repeat:  no-repeat;
  // background-size: 100% 100%;
  margin: auto;
  margin-top: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.img-4{
   width: 1200px;
  height: 340px;
}
.img-3{
   width: 1200px;
  height: 340px;
   display: flex;
    justify-content: center;
    align-items: center;
    img[lazy="loading"] {
		width: 100px;
		height: 100px;
	}
	// 实际图片大小
	img[lazy="loaded"] {
		  width: 1200px;
  height: 340px;
	}
}
.byte5{
  position: absolute;
  width: 390px;
  height: 228px;
}

.bounce3{
  background-color: #F6F8FB;
  padding-bottom: 100px;
}
.byte11{
  color: rgba(51, 51, 51, 1);
  font-size: 40px;
  padding-top: 80px;
}
.byte12{
  display: flex;
  justify-content: center;
}
.byte13{
  height: 200px;
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
  width: 585px;
  display: flex;
  align-items: center;
}
.byte15{
  width: 96px;
  height: 96px;
  margin-left: 48px;
}
.byte16{
  text-align: left;
  margin-left: 36px;
  width: 357px;
}
.btye17{
  color: rgba(51, 51, 51, 1);
  font-size: 24px;
}
.btye18{
  color: rgba(153, 153, 153, 1);
  font-size: 20px;
  margin-top: 11px;
}
.mt60{
  padding-top: 60px;
}
.ml30{
  margin-left: 30px;
}
.ml33{
  margin-left: 60px;
}
.mt30{
  margin-top: 30px;
}
.byte19{
  color: rgba(86, 86, 86, 1);
  font-size: 20px;
  margin-top: 24px;
}
.byte20{
   display: flex;
   justify-content: center;
   margin-top: 60px;
   margin-bottom: 100px;
}
.byte21{
  width: 380px;
  height: 529px;
}
.img-5{
  width: 380px;
  height: 529px;
  display: flex;
    justify-content: center;
    align-items: center;
    img[lazy="loading"] {
		width: 80px;
		height: 80px;
	}
	// 实际图片大小
	img[lazy="loaded"] {
		 width: 380px;
  height: 529px;
	}
}
.byte24{
  height: 8px;
  width: 8px;
}
.bounce5{
  padding-bottom: 100px;
  background-color: #F6F8FB;
}
.byte25{
  display: flex;
  justify-content: center;
}
.byte26{
  height: 196px;
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
  width: 380px;
  display: flex;
}
.byte23{
  width: 64px;
  height: 64px;
  margin-top: 24px;
  margin-left: 32px;
}
.byte27{
  margin-top: 24px;
  margin-left: 20px;
  text-align: left;
}
.byte28{
   color: rgba(51, 51, 51, 1);
  font-size: 20px;
}
.byte29{
  display: flex;
  align-items: center;
}
.byte30{
  color: rgba(153, 153, 153, 1);
  font-size: 16px;
  margin-left: 7px;
  width: 216px;
  text-align: left;
}
.byte40{
  color: rgba(153, 153, 153, 1);
  font-size: 16px;
  width: 216px;
  text-align: left;
}
.byte31{
  display: flex;
}
.byte32{
  height: 8px;
  width: 8px;
  margin-top: 7px;
}
.byte33{
   color: rgba(153, 153, 153, 1);
  font-size: 16px;
  margin-left: 7px;
  width: 216px;
}
.mt16{
  margin-top: 16px;
}
.mt8{
  margin-top: 8px;
}
</style>